@import '~bootstrap/scss/functions.scss';
@import '~bootstrap/scss/variables.scss';
@import '~bootstrap/scss/mixins.scss';

.resume {
	background-color: var(--resume-background);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	color: var(--main-text);
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	height: 100%;
	justify-content: space-around;
	max-height: 1400px;
	padding: 1rem 1.5rem 1rem 1.75rem;
	transition: background-color 0.2s linear, color 0.2s linear;
	@include media-breakpoint-down(md) {
		max-height: unset;
		padding: 0.5rem 0.9rem 1rem 1rem;
	}
}

.page-header {
	color: var(--text-header-color);
	font-weight: bold;
	margin-top: 0.85rem;
	margin-bottom: 0.25rem;
	transition: color 0.2s linear;
	text-transform: capitalize;
}

.page-sub-header {
	color: var(--text-header-color);
	font-size: 1.35rem;
	transition: color 0.2s linear;
	font-weight: 400;
}

.info {
	align-items: flex-end;
	display: flex;
	flex-direction: column;
	margin: 0 0 -2.5rem 0;
	p {
		margin: 0;
	}
	@include media-breakpoint-down(sm) {
		margin: 0 0 -0.5rem 0;
	}
}

.info-link {
	color: var(--accent);
	cursor: pointer;
	transition: color 0.2s linear;
	z-index: 10;
	&:hover {
		color: var(--accent);
		transition: color 0.2s linear;
	}
}

.icon {
	color: var(--icon-color);
	margin-right: 0.5rem;
	transition: color 0.2s linear;
}

.list {
	border-left: 1px solid var(--icon-color);
	list-style: none;
	padding-inline-start: 1rem;

	li {
		display: flex;
		.icon {
			height: 24px;
			min-width: 24px;
			width: 24px;
		}
		span {
			flex-grow: 1;
		}
	}
}

.skills-list {
	list-style: none;
	padding-inline-start: 0.25rem;
	margin: 0.5rem 0;
}

.employment-list,
.education-list {
	margin: 1.2rem 0;
}

.employment-body,
.education-body {
	min-height: 114px;
}

.employment-head,
.employment-info,
.education-head,
.education-info {
	padding-left: 1rem;
}

.summary-text {
	padding-left: 0.25rem;
}
