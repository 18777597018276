@import '~bootstrap/scss/functions.scss';
@import '~bootstrap/scss/variables.scss';
@import '~bootstrap/scss/mixins.scss';
@import '~bootstrap/scss/reboot.scss';
@import '~bootstrap/scss/type.scss';
@import '~bootstrap/scss/grid.scss';
@import '~bootstrap/scss/buttons.scss';
@import '~bootstrap/scss/transitions.scss';
@import '~bootstrap/scss/nav.scss';
@import '~bootstrap/scss/navbar.scss';
@import '~bootstrap/scss/utilities.scss';

html {
	background-color: #222;
	font-size: 16px;
	font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	min-height: 100vh;
}

body {
	background-color: var(--background-color);
	background: var(--background-gradient);
	font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	min-height: 100vh;
	transition: background-color 0.2s linear;
}

#root {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

h1,
h2,
h3,
p {
	margin: 0;
}
